/**
 * @file   src\containers\Login.js
 * @brief  This file is responsible for login page.
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../assets/styles/login.scss'
import logo from '../../assets/img/login-logo.svg';
import loginimg from '../../assets/img/login-illustration.svg';
import Strings from '../../assets/strings/Strings.json';
import Input from '../../components/Input';
import { _loginUserAdmin } from '../../actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { setAdmin } from '../../utils/localStorage';
import viewpass from '../../assets/img/view-password.svg'
import hidepass from '../../assets/img/hide-password.svg'
import * as alerts from '../../utils/Messages'
import * as constants from '../../utils/constants';

const AdminLogin = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ------- call API from functions and response from redux start ---------
  const signInUser = (loginData) => dispatch(_loginUserAdmin(loginData));
  const signInResponse = useSelector((RootReducer) => RootReducer.AuthReducer.getAuthResponse)
  // ------- call API from functions and response from redux start ---------


  // ------- state start -------
  const intitalValuesLoginData = {
    email: "",
    password: "",
    u_type: 1
  }
  const intitalformErrors = {
    email: "",
    password: ""
  }

  const [loginData, setLoginData] = useState(intitalValuesLoginData)
  const [signInResponseData, setSignInResponseData] = useState([])
  const [formErrors, setFormErrors] = useState(intitalformErrors)
  const [signInClicked, setSignInClicked] = useState(false)
  const [toastSuccessMessage, setToastSuccessMessage] = useState('')
  const [toastErrorMessage, setToastErrorMessage] = useState('')
  const [viewPassword, setViewPassword] = useState(false)

  const refEmail = useRef(null);
  const refPassword = useRef(null);
  // ------- state end -------

  // ------- useEffect start -------
  useEffect(() => {
    if (signInClicked && signInResponse) {
      setSignInResponseData(signInResponse);
    }
  }, [signInResponse])

  useEffect(() => {
    if (toastSuccessMessage !== '') {
      toast.success(toastSuccessMessage, {
        onClose: () => {
          setToastSuccessMessage('')
        }
      });
    }
  }, [toastSuccessMessage])

  useEffect(() => {
    if (toastErrorMessage !== '') {
      toast.error(toastErrorMessage, {
        onClose: () => {
          setToastErrorMessage('')
        }
      });
    }
  }, [toastErrorMessage])

  useEffect(() => {

    if (signInResponseData) {

      setSignInClicked(false)
      if (signInResponseData.apiStatus && signInResponseData.apiErrorCode === constants.STATUS_200) {
        // toast message
        setLoginData(intitalValuesLoginData)
        setToastSuccessMessage(signInResponseData.apiMessage)
        //response to localstorage

        if (signInResponseData.apiResponse && signInResponseData.apiResponse.user_info) {
          const adminInfo = {
            a_token: signInResponseData.apiResponse.user_info.a_token,
            r_token: signInResponseData.apiResponse.user_info.r_token,
            f_name: signInResponseData.apiResponse.user_info.f_name,
            l_name: signInResponseData.apiResponse.user_info.l_name
          };
          setAdmin(JSON.stringify(adminInfo));
          setTimeout(() => {
            navigate('/admin/managevendor')
          }, 100)
         }
        else {
          setToastErrorMessage(signInResponseData.apiMessage)
        }

      } else {
        setToastErrorMessage(signInResponseData.apiMessage)
      }
    }
  }, [signInResponseData])


  // checks from successfully validated, if so called sign in function
  useEffect(() => {
    if (signInClicked) {
      if (formErrors.email == '' && formErrors.password == '') {
        signInUser(loginData);
      }
    }
  }, [formErrors, signInClicked])
  // ------- useEffect end -------

  // -------  custom functions start -------

  // update values to state
  const formChange = (event) => {
    setLoginData(loginData => ({ ...loginData, [event.target.name]: event.target.value }))
  }


  /**
* login form validation
* @param {json} loginData 
* @returns json
*/

  const validateLogin = (loginData) => {
    let errors = { email: '', password: '' };
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let focusSet = false;
    if (!loginData.email) {
      errors.email = alerts.ENTER_EMAIL;
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    } else if (!reg.test(loginData.email)) {
      errors.email = alerts.ENTER_VALID_EMAIL;
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    }
    if (!loginData.password) {
      errors.password = alerts.ENTER_PASSWORD;
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    return errors;
  };

  // sign in button clicked
  const signIn = () => {
    const response = validateLogin(loginData);
    //set validation response to state
    setFormErrors(response)
    // set flag sign in button clicked
    setSignInClicked(true)
  }
  // -------  custom functions end  -------
  const submitForm = (event) => {
    event.preventDefault();
    signIn();
  }


  const submitFormOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      signIn();
    }
  }
  // -------  custom functions end  -------

  return (
    <Container fluid className='login'>
      <Container className='login-outer'>
        <form onSubmit={submitForm}>
          <Row>
            <Col lg='6' className='d-flex align-items-center justify-content-center flex-column login-left'>
              <img src={logo} className='logo' alt='NearBuy Logo' />
              <img src={loginimg} className='login-img' alt="NearBuy Login" />
              <p className='text-center'>{Strings.Login.TaglineAdmin}</p>
            </Col>
            <Col lg='6'>
              <div className='login-wrap'>
                <h1>{Strings.Admin.Login.Title}</h1>
                <Row>
                  <Col lg='12'>
                    <Input
                      label={Strings.Admin.Login.Label1}
                      id='email'
                      name='email'
                      type='email'
                      maxLength={200}
                      onChange={formChange}
                      value={loginData.email}
                      errorMessage={formErrors.email ? formErrors.email : ""}
                      onKeyDown={submitFormOnKeyDown}
                      txtRef={refEmail}
                    />
                  </Col>
                  <Col lg='12' className='position-relative'>
                    <Input
                      label={Strings.Admin.Login.Label2}
                      id='password'
                      name='password'
                      maxLength={15}
                      type={viewPassword ? 'text' : 'password'}
                      onChange={formChange}
                      value={loginData.password}
                      errorMessage={formErrors.password ? formErrors.password : ""}
                      onKeyDown={submitFormOnKeyDown}
                      txtRef={refPassword}
                    />
                    <span className='view-password position-absolute'><img src={loginData.password !== "" ? viewpass : hidepass} onClick={() => setViewPassword(!viewPassword)} /></span>
                  </Col>
                </Row>
                <span className='d-block text-end forgot-pass'><Link to='/forgotpassword' >{Strings.Admin.Login.Forgot}</Link></span>
                <Button variant='primary' onClick={signIn}>{Strings.Admin.Login.Btn}</Button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </Container>
  );
}
export default AdminLogin;
